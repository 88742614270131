// export const promoBar = () => {
// 	const promo_items = document.querySelectorAll('#promo_items');
// 	promo_items.forEach(item => {
// 		setTimeout(() => {
// 			item.classList.toggle('active');
// 		}, 5000);
// 	});
// };

export function showBanner() {
	let itemClass = 'promo_item';
	let activeClass = 'active';
	let wait = 10300;

	function toggleActive (element, index, maxIndex) {
		setTimeout(function(){
			element.classList.add(activeClass);
			setTimeout(function(){
				element.classList.remove(activeClass);
				if (index === maxIndex) {
					runLoop();
				}
			}, wait);
		}, wait * index);  
	}
	function runLoop () {
		const allItems = document.getElementsByClassName(itemClass);

		for (let index = 0; index < allItems.length; index++) {
			let element = allItems[index];
			toggleActive(element, index, allItems.length - 1);
		}
	}

	runLoop();
}
