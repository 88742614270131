export function searchPackingSupplies() {
	let input, filter, ul, li, a, i;
	input = document.querySelector('.search input');
	filter = input.value.toUpperCase();
	ul = document.querySelector('.supplies_wrap');
	li = ul.querySelectorAll('.supplies_item');

	for (i = 0; i < li.length; i++) {
		a = li[i].querySelector('h3');
		if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
			li[i].style.display = "";
		} else {
			li[i].style.display = "none";
		}
	}
}

export function filterPackingSupplies(element) {
	const value = element.dataset.value;
	const items = document.querySelectorAll('.supplies_item');
	items.forEach((item) => {
		let option = item.querySelector('h3');
		let filter = value.toUpperCase().split(',');
		if (filter.some(el => option.innerHTML.toUpperCase().indexOf(el.toUpperCase()) > -1 )) {
			item.style.display = "";
		} else {
			item.style.display = 'none';
		}
	});
}
