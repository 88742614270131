import { contactSubmit } from './contact.js';
import { maps } from './map.js';
import { AutomatitCarousel } from './automatit_carousel.m.js';
import { facilityCaro, facilityMap, unitFilters } from './facility.js';
import { showBanner } from './promo_bar.js';
import { searchPackingSupplies, filterPackingSupplies } from './packing_supplies.js';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.getElementById('supplies')) {
	document.querySelector('.search input').addEventListener('keyup', searchPackingSupplies);

	const customSelect = document.querySelector(".filters");
	const selectedOption = document.querySelector("#selected-option");
	const options = document.querySelectorAll(".option");

	customSelect.addEventListener("click", function(e) {
		selectedOption.value = e.target.textContent;
	});

	selectedOption.addEventListener("focus", function() {
		customSelect.querySelector(".options").style.display = "block";
	});

	options.forEach(function(option) {
		option.addEventListener("click", function() {
			selectedOption.value = option.textContent;
			customSelect.querySelector(".options").style.display = "none";
			filterPackingSupplies(option);
		});
	});
}

const _doubleClick = () => {
	[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
		el.addEventListener('click', e => {
			console.log(e);
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if (!el.hasAttribute('clicked') && window.screen.width > 750) {
					e.preventDefault();
					el.setAttribute('clicked', true);
				}
			}
		});
	});
};

// Home carousel
export const initHomeCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero_caro'),
		showDots: true,
		intervalTiming: 5000,
		useChevrons: false,
		autoVerticalCentering: false,
	});
};

if(document.querySelector('#home_hero_caro')) {
	initHomeCaro();
}

unitFilters.init();
facilityCaro.init();
facilityMap.init();
_doubleClick();


if (document.querySelector('#locations_map')) {
	maps.init();
}

//used for dropdowns in faq and tips pages

if (document.querySelector('.faqTips-content')) {
	const dropBtns = document.querySelectorAll('.faqTips-content');
	[...dropBtns].forEach(dropBtn => {
		dropBtn.addEventListener('click', (event) => {
			let parentEl = event.currentTarget.querySelector(".faq-text-dropdown");
			let faqContent = event.currentTarget;
	
			if(parentEl.style.display === 'block'){
				parentEl.style.display = 'none';
			}else{
				parentEl.style.display = 'block';	
			}	
			
			faqContent.classList.toggle('open');
		});
	});
}

if (document.querySelector('.item')) {
	const dropItems = document.querySelectorAll('.item');
	const dropDownItems = document.querySelectorAll('.dropdown');
	const dropDown = document.querySelector('.dropdown_background');

	dropItems.forEach((item) => {
		if (!item.classList.contains('link')) {
			item.addEventListener('mouseover', () => {
				dropDown.classList.add('active');
			});
			item.addEventListener('mouseout', () => {
				dropDown.classList.remove('active');
			});
		}
	});

	dropDownItems.forEach((item) => {
		item.addEventListener('mouseover', () => {
			dropDown.classList.add('active');
		});
		item.addEventListener('mouseout', () => {
			dropDown.classList.remove('active');
		});
	});
}

if (document.querySelector('#hamburger')) {
	const hamburger = document.getElementById('hamburger');
	const nav_items = document.querySelector(".nav_items");
	const nav_dropdown = document.querySelectorAll(".item");

	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle("open");
		nav_items.classList.toggle("open");
	});

	if (nav_dropdown) {
		nav_dropdown.forEach(item => {
			item.addEventListener('click', () => {
				item.classList.toggle("clicked");
			});
		});
	}

	if (document.getElementById('to_top')) {
		const to_top = document.getElementById("to_top");
		to_top.addEventListener('click', () => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		});
	}
}


//dropdown arrow functionality
if (document.getElementById('contact_form')) {
	const dropBtn = document.querySelector('select');
	
	dropBtn.addEventListener('click', (event) =>{
		let dropElement = event.currentTarget.closest('#contact_form');
		dropElement.classList.toggle('open');
	
		//rotate arrow if open and clicked outside of select area
		window.onclick = function(e){
			if(!e.target.matches('select') && dropElement.classList.contains('open')){
				dropElement.classList.toggle('open');
			}
		};
	});
}

if(document.getElementById('contact_form')) {
	//dropdown arrow functionality
	const dropBtn = document.querySelector('select');
	
	dropBtn.addEventListener('click', (event) =>{
		let dropElement = event.currentTarget.closest('#contact_form');
		dropElement.classList.toggle('open');
	
		//rotate arrow if open and clicked outside of select area
		window.onclick = function(e){
			if(!e.target.matches('select') && dropElement.classList.contains('open')){
				dropElement.classList.toggle('open');
			}
		};
	});
}

if(document.querySelector('#promo')) {
	showBanner();
}
// // Get modal element
// const modal = document.getElementById('simpleModal');
// // Get open modal button
// const modalBtn = document.getElementById('modalBtn');
// // Get close button
// const closeBtn = document.getElementsByClassName('closeBtn')[0];

// // Listen for open click
// modalBtn.addEventListener('click', openModal);
// // Listen for close click
// closeBtn.addEventListener('click', closeModal);
// // Listen for outside click
// window.addEventListener('click', outsideClick);


// // Open modal
// function openModal(){
// 	modal.style.display = 'block';
// }

// // Close modal
// function closeModal(){
// 	modal.style.display = 'none';
// }

// // Click outside and close
// function outsideClick(e){
// 	if(e.target === modal){
// 		modal.style.display = 'none';
// 	}
// }

const modals = document.querySelectorAll('.modal-open');

modals.forEach((element) => {
	element.addEventListener('click', () => {
		let popupModal = document.getElementById(element.dataset.popupTrigger);
		let popupModalCloseButton = document.getElementById(element.dataset.popupTrigger).getElementsByClassName("closeBtn")[0];
	
		popupModal.classList.toggle("show-modal");
		popupModalCloseButton.addEventListener("click", () => {
			popupModal.classList.remove("show-modal");
		});
	});
});
